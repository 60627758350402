// Create new transition animation
let BarbaTransition = Barba.BaseTransition.extend({

    // Transition starts
    start : function() {

        let body = document.querySelector('body')

        body.classList.add('is-transitioning');

        this.newContainerLoading.then(this.finish.bind(this));
    },

    // Transition is done
    finish : function() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            document.querySelector('body').classList.remove('is-transitioning');
            this.done();
        } , 500)
    }
});