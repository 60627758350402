let Shop = Barba.BaseView.extend({

    // Register the current namespace
    namespace : 'shop',

    // Init the lazyload
    lazyload : function () {
        let lazyObject = new LazyLoad({ elements_selector : '.image-is-lazy' });
    },

    // Call all the functions on enter
    onEnter : function () {
        this.lazyload();
    },

    // WHen the page is gone destroy all the listener
    onLeaveCompleted : function () {
    }
});