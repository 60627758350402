// @codekit-prepend "./views/home"
// @codekit-prepend "./views/shop"
// @codekit-prepend "./transition/fade"

// Initialize the views
Home.init();
Shop.init();

// Set up barba configurations
Barba.Pjax.Dom.containerClass = "mm-container";
Barba.Pjax.Dom.wrapperId      = "mm";

// Set the new custom transition
Barba.Pjax.getTransition = function() { return BarbaTransition; };

// Initialize barbajs
Barba.Pjax.start();
